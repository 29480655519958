"use strict";
import IndexPage from "../views/index/index.vue"

export default [

    {   //首页
        path: '/',
        name: 'name',
        component: IndexPage,
    },
    //作品存证
    {
        path: '/opus',
        name: 'opus',
        component: ()=>import('@/views/pc/notarization/works/index.vue'),
        meta:{
            num:1
        }
    },
    {
        path: '/webstore',
        name: 'webstore',
        component:()=>import('@/views/pc/notarization/web/index.vue'),
        meta:{
            num:1
        }
    },
    {
        path: '/translate',
        name: 'translate',
        component:()=>import('@/views/pc/notarization/translate/index.vue'),
        meta:{
            num:1
        }
    },
    // 公证提取
    {
        path: '/deposit',
        name: 'deposit',
        component:()=>import('@/views/pc/notarization/deposit/index.vue'),
        meta:{
            num:1
        }
    },
    // 在线申办（零跑腿）
    {
        path: '/online',
        name: 'online',
        component:()=>import('@/views/pc/notarization/online/index.vue'),
        meta:{
            num:1
        }
    },
    // 版权运营
    {
        path: '/business',
        name: 'business',
        component:()=>import('@/views/pc/assort/business/index.vue'),
        meta:{
            num:1
        }
    },
    // 版权监测
    {
        path: '/monitor',
        name: 'monitor',
        component:()=>import('@/views/pc/assort/monitor/index.vue'),
        meta:{
            num:1
        }
    },
    // 营销推广
    {
        path: '/promotion',
        name: 'promotion',
        component:()=>import('@/views/pc/assort/promotion/index.vue'),
        meta:{
            num:1
        }
    },
    //科创补贴
    {
        path: '/subsidy',
        name: 'subsidy',
        component:()=>import('@/views/pc/assort/subsidy/index.vue'),
        meta:{
            num:1
        }
    },

    // 知产服务
    {
        path: '/service',
        name: 'service',
        component:()=>import('@/views/pc/assort/service/index.vue'),
        meta:{
            num:1
        }
    },
    // 走进基地
    {
        path: '/enterBase',
        name: 'enterBase',
        component:()=>import('@/views/pc/enterBase/index.vue'),
        meta:{
            num:1
        }
    },
    // 维权服务
    {
        path: '/safeguard',
        name: 'safeguard',
        component:()=>import('@/views/pc/safeguard/index.vue'),
        meta:{
            num:1
        }
    },
    // 资讯中心
    // {
    //     path: '/information',
    //     name: 'information',
    //     component:()=>import('@/views/pc/information/index.vue'),
    //     meta:{
    //         num:1
    //     }
    // },
    //  // 资讯中心详情
    //  {
    //     path: '/details',
    //     name: 'details',
    //     component:()=>import('@/views/pc/information/details/index.vue'),
    //     meta:{
    //         num:1
    //     }
    // },
    {
        path: '/information',
        name: 'information',
        component:()=>import('@/views/pc/informationes/index.vue'),
        meta:{
            num:1
        }
    },
    // 资讯中心详情
    {
        path: '/details',
        name: 'details',
        component:()=>import('@/views/pc/informationes/details/index.vue'),
        meta:{
            num:1
        }
    },
    //关于我们

    {
        path: '/about',
        name: 'about',
        component:()=>import('@/views/pc/about/index.vue'),
        meta:{
            num:1
        }
    },
    //h5宣传页
    {
        path: '/wap/opus',
        name: 'opus',
        component: ()=>import('@/views/wap/works/index.vue'),
    },
    {
        path: '/wap/webstore',
        name: 'webstore',
        component:()=>import('@/views/wap/notarization/webstore/index.vue'),
    },
    {
        path: '/wap/translate',
        name: 'translate',
        component:()=>import('@/views/wap/notarization/translate/index.vue'),
    },
    {
        path: '/wap/deposit',
        name: 'deposit',
        component:()=>import('@/views/wap/notarization/deposit/index.vue'),

    },
    // 在线申办（零跑腿）
    {
        path: '/wap/online',
        name: 'online',
        component:()=>import('@/views/wap/notarization/online/index.vue'),
    },
    {
        path: '/wap/enterBase',
        name: 'enterBase',
        component:()=>import('@/views/wap/enterBase/index.vue'),
    },

    // 版权运营
    {
        path: '/wap/business',
        name: 'business',
        component:()=>import('@/views/wap/assort/business/index.vue'),
    },
    // 版权监测
    {
        path: '/wap/monitor',
        name: 'monitor',
        component:()=>import('@/views/wap/assort/monitor/index.vue'),
    },
    // 营销推广
    {
        path: '/wap/promotion',
        name: 'promotion',
        component:()=>import('@/views/wap/assort/promotion/index.vue'),
    },
    //科创补贴
    {
        path: '/wap/subsidy',
        name: 'subsidy',
        component:()=>import('@/views/wap/assort/subsidy/index.vue'),
    },

    // 知产服务
    {
        path: '/wap/service',
        name: 'service',
        component:()=>import('@/views/wap/assort/service/index.vue'),
    },
    // 资讯中心
    // {
    //     path: '/wap/information',
    //     name: 'information',
    //     component:()=>import('@/views/wap/information/index.vue'),
    // },
    // {
    //     path: '/wap/details',
    //     name: 'details',
    //     component:()=>import('@/views/wap/information/details/index.vue'),
    // },
    {
        path: '/wap/information',
        name: 'information',
        component:()=>import('@/views/wap/informationes/index.vue'),
    },
    //资讯中心详情
   
    {
        path: '/wap/details',
        name: 'details',
        component:()=>import('@/views/wap/informationes/details/index.vue'),
    },
    //关于我们
    {
        path: '/wap/about',
        name: 'about',
        component:()=>import('@/views/wap/about/index.vue'),
    },

    ///////////////

    {   //商品模块相关路由
        path: '/commodity/',
        name: 'productModule',
        children: [
            {
                path: 'buy',
                name: 'BuyGoodsList'
            },
            {
                path: 'detail',
                name: 'ProductDetail'
            },
            {
                path: 'shoppingCart',
                name: 'ShoppingCart'
            },
            {
                path: "storeMainPage",
                name: "ProductStoreMainPage",
            },
            {
                path: "createOrder",
                name: "ProductCreateOrder",
            },
            {
                path: "addressList",
                name: "AddressList",
            },
            {
                path: "evaluateList",
                name: "ProductEvaluateList",
            },
            {
                path: "publish",
                name: "ProductPublish",
            },
            {
                path: "publishAdd",
                name: "ProductPublishAdd",
            },
            {
                path: "freightTemplate",
                name: "FreightTemplate",
            },
        ]
    },
    {   //需求模块相关路由
        path: '/requirement/',
        name: 'needModule',
        children: [
            {
                path: 'list',
                name: 'NeedList'
            },
            {
                path: 'detail',
                name: 'NeedDetail'
            },
            {
                path: 'IspList',
                name: 'NeedIspList'
            },
            {
                path: "publish",
                name: "NeedPublish",
            },
            {
                path: "publishedList",
                name: "NeedPublishedList",
                meta: { title: "我发布的需求" },
            },
            {
                path: "publishedDetail",
                name: "NeedPublishedDetail",
                meta: { title: "需求详情" },
            },
            {
                path: "storeMainPage",
                name: "NeedStoreMainPage",
            },
        ]
    },
    {
        path: "/collection",
        name: "collection",
    },
    {
        path: "/storeSetting",
        name: "StoreSetting",
    },
    // 404页面
    // {
    //     path: '/404',
    //     name: "404",
    //     component: () => import('@/views/404.vue'),
    //     hidden: true
    // },
    // // 404 page must be placed at the end !!!
    // { path: '*', redirect: '/404', }
];
